import React, {useState} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FeedbackDialog from '../FeedbackDialog/FeedbackDialog';

const useStyles = makeStyles(theme => ({
    footerContent: {
        padding: theme.spacing(4, 0, 4),
       
      },
    copyright: {
        padding: theme.spacing(1, 0, 1),
    },
    button: {
      margin: theme.spacing(1),
    
    },
    container:{
      justifyContent: 'center',
      alignContent: 'center'
    }
  }));

export default function Footer() {
    const [showContact, setShowContact] = useState(false);
    const classes = useStyles();

    const onContactClicked = () => 
    {
      setShowContact(true);
    }

    const onContactClosed = () => 
    {
      setShowContact(false);
    }

    return (    
    <Paper className={classes.footerContent} >
    <FeedbackDialog showContact={showContact} onContactClosed={onContactClosed}/>
      <Container maxWidth="lg">
          <Grid container justify = "center" direction="column" alignItems='center'>
          <Grid item>
            <Typography variant="body1" align="center" color="textPrimary" >
              Kamality [Kama · Sutra  · Augmented · Reality]  is a modern take on the ancient teachings of sexual wellness and empowerment. Our mission is to improve sexual positivity, connection, education, and healthy lifestyles. We're just getting started.
            </Typography>
            </Grid>
            <Grid item>
              <Button color="primary" className={classes.button} onClick={onContactClicked}>
                Contact Us
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="center" color="textSecondary" gutterBottom className={classes.copyright}>
                Copyright © 2019, KAMALITY
              </Typography>
            </Grid>
            </Grid>
        </Container>
    </Paper>
    );
}