import React, {useState,useEffect} from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import ModelViewer from '../ModelViewer/ModelViewer';
import {Link} from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ReactGA from 'react-ga';
import Skeleton from '@material-ui/lab/Skeleton';

import firebase from 'firebase/app';
//import { useTheme } from '@material-ui/styles';
//waypoint
//import { Waypoint } from 'react-waypoint';

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      cardMedia: {
        paddingTop: '56.25%', // 16:9
      },
      cardContent: {
        flexGrow: 1,
      },
      cardButton: {
        width: '100%',
        textAlign: 'left',
        fontSize: 18
      },
      cardBodyText: {
        paddingBottom: 12,
      },
      cardTitleRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%'
      },
      cardTitle:{
        float: 'left',
        width: '88%',
        fontSize: 30
      },
      cardActionIcon:{
        float: 'right',
      },
      chip:{
        margin: '3px'
      }
}));

const getPositionDifficultyColor = (difficulty) => {
    switch(difficulty)
    {
      case 'Beginner':
        return '#88997a';
      case 'Intermediate':
        return '#9e9b6d';
      case 'Advanced':
        return '#e3848c';
      default:
        return '#88997a';
    }
};

export default function PositionCard(props) {
  
    const [GLB, setGLB] = useState(null);
    const [USDZ, setUSDZ] = useState(null);

    useEffect(() => {
      // Create a reference with an initial file path and name
      const storage = firebase.storage();
      storage.ref(`GLB/${props.position.uid}.glb`).getDownloadURL().then( url => {
        setGLB(url);
      })
      .catch(error => {
        //console.log(error);
      })

      storage.ref(`USDZ/${props.position.uid}.usdz`).getDownloadURL().then( url => {
        setUSDZ(url);
      })
      .catch(error => {
        //console.log(error);
      })

    }, []);

    const classes = useStyles();

    const share = (event) => {
      if (navigator.share) {
        navigator.share({
            title: RichText.asText(props.position.data.title),
            text: `${RichText.asText(props.position.data.title)}: ${RichText.asText(props.position.data.subtitle)}`,
            url: `https://kamality.com/positions/${props.position.uid}`,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
        ReactGA.event({
          category: 'Share',
          action: 'Share Position From Card',
          label: RichText.asText(props.position.data.title)
        });
      }
    }

    const shareIcon = (
    <IconButton aria-label="share" className={classes.cardActionIcon} color='primary' onClick={share}>
      <ShareIcon />
    </IconButton>);

    const playListIcon = (props.isOnPlaylist) ?
     (<IconButton aria-label="remove from playlist" className={classes.cardActionIcon} color='primary' onClick={() => props.onRemoveFromPlaylist(props.position.uid)}>
        <PlaylistAddCheckIcon/>
      </IconButton>)
      : (<IconButton aria-label="add to playlist" className={classes.cardActionIcon} color='primary' onClick={() => props.onAddToPlaylist(props.position.uid)}>
         <PlaylistAddIcon/>
     </IconButton>);

    return(
      <Fade in={true} timeout={1500}>
        <Card className = {classes.card} elevation={4}>
            {
              (GLB) 
                ?  <ModelViewer interactionPrompt={(props.index === 0)?'auto':'none'} width='100%' height='200px' zoom="75%" src={GLB} usdz={USDZ} alt={`${RichText.asText(props.position.data.title)} - ${RichText.asText(props.position.data.subtitle)}`}/>
                : <Skeleton variant="rect" width={'100%'} height={200} />
            }
            <CardContent className = {classes.cardContent}>
            <div className={classes.cardTitleRow}>
              <Typography variant="h5" component="h2" className={classes.cardTitle} gutterBottom>
                  {RichText.asText(props.position.data.title)}
              </Typography>
              
            </div>
            <Typography variant="subtitle1"  style={{color: getPositionDifficultyColor(props.position.data.difficulty)}} gutterBottom>
                    {props.position.data.difficulty}
            </Typography>
            <Typography className = {classes.cardBodyText}>
                {RichText.asText(props.position.data.subtitle)}
            </Typography>
            <div className={classes.categories}>
                {
                    props.position.tags.map(tag => (
                    <Chip 
                    key={tag}
                        label={tag} 
                        className={classes.chip} 
                        variant="outlined" 
                        color={(props.currentTags.indexOf(tag)> -1) ? 'primary' : 'default'}
                        size="medium" 
                        clickable={true} 
                        onClick={() => {props.tagClicked(tag)}}/>
                    ))
                }
            </div>
            </CardContent>
            <CardActions>
              <Link to={{ pathname: `/positions/${props.position.uid}`, state: { showModal: true}}}  style={{width:'100%', textDecoration: 'none' }} >
                <Button fullWidth size="large" color="primary" className={classes.cardButton} > {/*onClick={() => {props.cardClicked(props.position.id)}}*/} 
                    LEARN MORE
                </Button>
              </Link> 
              {
                playListIcon
              }
              {(navigator.share)? shareIcon : null}
            </CardActions>
        </Card>
       </Fade>
    );
}