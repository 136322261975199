import React, {useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { RichText, Elements } from 'prismic-reactjs';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {Link} from 'react-router-dom';
import UseWindowDimensions from '../../hooks/useWindowDimensions';
import { sortableContainer, sortableElement, sortableHandle,} from 'react-sortable-hoc';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Input from '@material-ui/core/Input';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@material-ui/core/Drawer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const DragHandle = sortableHandle(() => 
    <ListItemIcon >
      <DragIndicatorIcon  color="primary"/>
     </ListItemIcon>);

//selected={(index===props.currentPlayIndex)?true:false
//RichText.asText(position.data.title)
//position.tags.slice(0,5).join(', ')
//button selected={((index===currentPlayIndex)?true:false)} onClick={()=>selectListItem(index)}
const SortableItem = sortableElement(({title, tags, currentPlayIndex, selectListItem, sortIndex, onViewContentClicked}) => (
    <ListItem button selected={((sortIndex===currentPlayIndex)?true:false)} onClick={()=>selectListItem(sortIndex)}>
        <DragHandle />
        <ListItemText  
            primary= {
                <Typography noWrap={true}>{title}</Typography> 
                }
            secondary= {
                <Typography noWrap={true} variant="caption" color="textSecondary">{tags}</Typography>
            }
        />
        <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="more info" color="primary" onClick={()=>onViewContentClicked(sortIndex)}>
                <NavigateNextIcon />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>
));

//this is being rendered over and over...bc of the progress bar state
const SortableListContainer = sortableContainer(({ playlistPositions, listHeight, currentPlayIndex, selectListItem, onViewContentClicked }) => (
    <List component="div" style={{ maxHeight: listHeight, width: '100%', overflow: 'auto',}}>
      {playlistPositions.map((position, index) => {
        return (<SortableItem 
            key={position.id} 
            index={index}
            sortIndex={index}
            onViewContentClicked={onViewContentClicked}
            title={RichText.asText(position.data.title)}  
            tags={position.tags.slice(0,5).join(', ')} 
            currentPlayIndex={currentPlayIndex}
            selectListItem={selectListItem}/>)
      })}
    </List>
));

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: 'relative',
        },
        [theme.breakpoints.up('md')]: {
            minWidth: '400px',
            position: 'absolute',
            top:'5%',
            left: '5%',
        },    
      },
      inputHeader: {
        fontSize: 35,
        marginBottom: '6px'
      },
      header: {
        height: '50px',
       // position: 'relative',
        margin: theme.spacing(6),
      },
      subheader: {
        display: 'flex',
        alignItems: 'center',
       
      },
      iconButton:{
        marginBottom: '12px',
      },
      list: {
        width: '100%',
        overflow: 'auto',
       
      },
      contentView:{
        overflow: 'auto',
        padding: theme.spacing(2)
      },
      noPositionsText: {
        margin: theme.spacing(6),
      }
}));

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
    return Object.assign(props || {}, { key });
  };

  const htmlSerializer = function(type, element, content, children, key) {
    var props = {};
    switch(type) {
      case Elements.paragraph:
            props = {variant:'body1', padding:'8', gutterBottom:true, paragraph:true};
            return React.createElement(Typography, propsWithUniqueKey(props, key), children);
        case Elements.heading1:
            props = {variant:'h3', padding:'12', gutterBottom:true, paragraph:true};
            return React.createElement(Typography, propsWithUniqueKey(props, key), children);
        case Elements.heading2:
            props = {variant:'h4', padding:'12', gutterBottom:true, paragraph:true};
            return React.createElement(Typography, propsWithUniqueKey(props, key), children);
        case Elements.heading3:
            props = {variant:'h6', padding:'12', gutterBottom:true, paragraph:true};
            return React.createElement(Typography, propsWithUniqueKey(props, key), children);
        case Elements.heading4:
            props = {variant:'h6', padding:'12', gutterBottom:true, paragraph:true};
            return React.createElement(Typography, propsWithUniqueKey(props, key), children);
        case Elements.heading5:
            props = {variant:'h7', padding:'12', gutterBottom:true, paragraph:true};
            return React.createElement(Typography, propsWithUniqueKey(props, key), children);
        case Elements.list: // Unordered List
            return React.createElement('ul', propsWithUniqueKey(props, key), children);
        case Elements.listItem: // Unordered List Item
            const bulletText = React.createElement(Typography, {variant:'body1'}, children);
            return React.createElement('li', propsWithUniqueKey(props, key), bulletText);  
        case Elements.oList: // Ordered List
            return React.createElement('ol', propsWithUniqueKey(props, key), children);
        case Elements.oListItem: // Ordered List Item
            const bulletText2 = React.createElement(Typography, {variant:'body1'}, children);
            return React.createElement('li', propsWithUniqueKey(props, key), bulletText2);
        case Elements.hyperlink:
            props = {to:linkResolver(element.data), target:"_blank"};
            return React.createElement(Link, propsWithUniqueKey(props, key), children);
      default: 
        return null;
    }
  };

// Link Resolver
const linkResolver = (doc) => {
    // Define the url depending on the document type
    if (doc.type === 'card') {
      return '/positions/' + doc.uid;
    } 
    // Default to homepage
    return '/';
  }

export default function Playlist(props) {

    const [isContentView, setContentView] = useState(false);
    

    const { width, height } = UseWindowDimensions();
    const theme = useTheme();
    const classes = useStyles();

    const panelHeight = height-(height * 0.15);
    const listHeight = panelHeight - 175;

   // console.log(`panel height: ${panelHeight} list height: ${listHeight}`);

   const onBackOutofContentClicked = () => {
        setContentView(false);
   }

   const onViewContentClicked = (index) => {
       //props.playlistPositions[props.currentPlayIndex].data.title
       //console.log(props.playlistPositions[props.currentPlayIndex].data.title);
        props.setCurrentPlayIndex(index);
        setContentView(true);
   }

   const onRemoveItemFromPlaylist = (index) => {
        setContentView(false);
        props.onRemoveItem(index);
   }

   const onNameChange = (e) => {
        props.setPlaylistName(e.target.value);
   }

  /* <Typography variant="h4" gutterBottom color="primary">
   {props.playlistName}
    </Typography>*/

   const menuWith = useMediaQuery(theme.breakpoints.up('md')) ? (width / 3) : width;
   const menuHeight = useMediaQuery(theme.breakpoints.up('md')) ? panelHeight : height;
   const collapseButton = useMediaQuery(theme.breakpoints.up('md')) 
        ? null 
        : <IconButton aria-label="hide menu" color="primary" onClick={()=>props.onExpandDrawer(false)} style={{margin:'6px'}}>
                <ExpandMoreIcon />
          </IconButton>;

   const PlayListMenuView = (
        <Paper className={classes.root} style={{height:menuHeight, width:menuWith}}>
            {collapseButton}
            <div className={classes.header} >
                <Input value={props.playlistName} className={classes.inputHeader} color="primary" onChange={(e) => onNameChange(e)}/>
                <div className={classes.subheader}>
                    <Typography variant="subtitle1" gutterBottom color="textSecondary">
                        {(props.playlistPositions)?`${props.playlistPositions.length} Positions`:null} 
                    </Typography>
                </div>
            </div>
            {(props.playlistPositions && props.playlistPositions.length > 0)?
                <SortableListContainer
                    playlistPositions={props.playlistPositions}
                    listHeight={listHeight}
                    currentPlayIndex={props.currentPlayIndex}
                    selectListItem={props.setCurrentPlayIndex}
                    onSortEnd={props.onSortEnd}
                    useDragHandle={true}
                    onViewContentClicked={onViewContentClicked}
                    lockAxis="y"
                    distance={5}
                    />
            :
            <Typography variant="body1" className={classes.noPositionsText}>Your playlist is empty. Add some positions and they will appear here.</Typography>}
        </Paper>
   );

   const contentView = (
    <Paper className={classes.root} style={{height:menuHeight, width:menuWith}} >
       
        <div>
        <IconButton aria-label="navigate back" color="primary" onClick={()=>onBackOutofContentClicked()}>
             <NavigateBeforeIcon />
        </IconButton>
        <IconButton aria-label="delete" style={{float:"right"}} onClick={()=>onRemoveItemFromPlaylist(props.currentPlayIndex)}>
              <DeleteOutlineIcon />
         </IconButton>                       
        </div>
        {(props.playlistPositions && props.playlistPositions.length !== 0) 
            ?
                <div className={classes.contentView} style={{ maxHeight: menuHeight - 50}}>
                    <Typography variant="h3" color="primary" style={{marginBottom:'18px'}}>{RichText.asText(props.playlistPositions[props.currentPlayIndex].data.title)}</Typography>
                    <RichText render={props.playlistPositions[props.currentPlayIndex].data.body} htmlSerializer={htmlSerializer}  linkResolver={linkResolver}/>
                </div>
                : null
        }
    </Paper> 
   )

   const currentView = (isContentView) ? contentView : PlayListMenuView;

   return (
       (useMediaQuery(theme.breakpoints.up('md'))) 
            ? currentView 
            : <Drawer anchor="bottom" open={props.drawerOpen} onClose={() => props.onExpandDrawer(false)}>
                {currentView}
              </Drawer>
   )
   
}