import React, {useState,useEffect, useRef} from 'react';
import { useTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ModelViewer from '../ModelViewer/ModelViewer'
import Aux from '../../hoc/Aux'
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import Paper from '@material-ui/core/Paper';
import {Helmet} from "react-helmet";
import ReactGA from 'react-ga';
import {Link} from 'react-router-dom';
import { RichText, Elements } from 'prismic-reactjs'
import firebase from 'firebase/app';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  modelLayer: {
    position: 'relative'
  },
  contentLayer : {
  
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  categories: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  titleText: {
    position: 'absolute',
    left: theme.spacing(2),
    bottom: theme.spacing(2),
  }
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    overflow: 'visible'
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography className={classes.titleText} variant="h3">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
    return Object.assign(props || {}, { key });
  };
   
  // -- HTML Serializer
  // This function will be used to change the way the HTML is loaded
const htmlSerializer = function(type, element, content, children, key) {
    var props = {};
    switch(type) {
      // Add a class to paragraph elements
      case Elements.paragraph:
            props = {variant:'body1', padding:'8', gutterBottom:true, paragraph:true};
            return React.createElement(Typography, propsWithUniqueKey(props, key), children);
        case Elements.heading1:
            props = {variant:'h3', padding:'12', gutterBottom:true, paragraph:true};
            return React.createElement(Typography, propsWithUniqueKey(props, key), children);
        case Elements.heading2:
            props = {variant:'h4', padding:'12', gutterBottom:true, paragraph:true};
            return React.createElement(Typography, propsWithUniqueKey(props, key), children);
        case Elements.heading3:
            props = {variant:'h5', padding:'12', gutterBottom:true, paragraph:true};
            return React.createElement(Typography, propsWithUniqueKey(props, key), children);
        case Elements.heading4:
            props = {variant:'h5', padding:'12', gutterBottom:true, paragraph:true};
            return React.createElement(Typography, propsWithUniqueKey(props, key), children);
        case Elements.heading5:
            props = {variant:'h7', padding:'12', gutterBottom:true, paragraph:true};
            return React.createElement(Typography, propsWithUniqueKey(props, key), children);
        case Elements.list: // Unordered List
            return React.createElement('ul', propsWithUniqueKey(props, key), children);
        case Elements.listItem: // Unordered List Item
            const bulletText = React.createElement(Typography, {variant:'body1'}, children);
            return React.createElement('li', propsWithUniqueKey(props, key), bulletText);  
        case Elements.oList: // Ordered List
            return React.createElement('ol', propsWithUniqueKey(props, key), children);
        case Elements.oListItem: // Ordered List Item
            const bulletText2 = React.createElement(Typography, {variant:'body1'}, children);
            return React.createElement('li', propsWithUniqueKey(props, key), bulletText2);
        case Elements.hyperlink:
            props = {to:linkResolver(element.data), replace:true};
            return React.createElement(Link, propsWithUniqueKey(props, key), children);
      default: 
        return null;
    }
  };

  	
// Link Resolver
const linkResolver = (doc) => {
  // Define the url depending on the document type
  if (doc.type === 'card') {
    return '/positions/' + doc.uid;
  } 
  // Default to homepage
  return '/';
}

  /*const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} in={true} timeout={1500} {...props} />;
  });*/

export default function PositionDialog(props) {

  const [GLB, setGLB] = useState(null);
  const [USDZ, setUSDZ] = useState(null);

  const dialogRef = useRef(null);

  useEffect(() => {

    if(!props.open || props.position === null) return

    
    if(dialogRef.current != null)
    {
      dialogRef.current.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
     

    // Create a reference with an initial file path and name
    const storage = firebase.storage();
    storage.ref(`GLB/${props.position.uid}.glb`).getDownloadURL().then( url => {
      //console.log(url);
      setGLB(url);
    })
    .catch(error => {
      //console.log(error);
    })

    storage.ref(`USDZ/${props.position.uid}.usdz`).getDownloadURL().then( url => {
      //console.log(url);
      setUSDZ(url);
    })
    .catch(error => {
      //console.log(error);
    })

  }, [props.open, props.position, dialogRef]);

  let body = null;
  const classes = makeStyles(styles);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  

  const share = (event) => {
    if (navigator.share) {
      navigator.share({
          title: RichText.asText(props.position.data.title),
          text: `${RichText.asText(props.position.data.title)}: ${RichText.asText(props.position.data.subtitle)}`,
          url: `https://kamality.com/positions/${props.position.uid}`,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
        ReactGA.event({
          category: 'Share',
          action: 'Share Position From Dialog',
          label: RichText.asText(props.position.data.title)
        });
    }
  }

  const shareSection = (
      <Button
          color="primary"
          onClick={share}
        >
          <ShareIcon style={{marginRight: "6px"}} />
          Share
      </Button>
    
  );

  const playListIcon = (props.isOnPlaylist) ?
     (<Button aria-label="remove from playlist" color='primary' onClick={() => props.onRemoveFromPlaylist(props.position.uid)}>
        <PlaylistAddCheckIcon style={{marginRight: "6px"}}/>
        Remove From Playlist
      </Button>)
      : (
      <Button aria-label="add to playlist" color='primary' onClick={() => props.onAddToPlaylist(props.position.uid)}>
        <PlaylistAddIcon style={{marginRight: "6px"}}/>
        Add To Playlist
      </Button>);

  if(props.position != null)
  {
    //console.log(props.position.GLB)
    
    body = 
    <Aux style={{position: 'relative'}}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{RichText.asText(props.position.data.title)}</title>
      <meta name="description" content={RichText.asText(props.position.data.subtitle)}/>
      <meta name="keywords" content={props.position.tags.join()}/>
      <meta name="author" content="Kamality"/>
    </Helmet>
    <div className={classes.modelLayer}>
      <ModelViewer  interactionPrompt='auto' width='100%' height={(fullScreen) ? '80vh' : '500px'} src={GLB} usdz={USDZ} alt={`${RichText.asText(props.position.data.title)} - ${RichText.asText(props.position.data.subtitle)}`}>
        <DialogTitle id="customized-dialog-title" onClose={props.onClose} disableTypography={true} >
        </DialogTitle>
        <Typography variant="h3"  style={{margin: theme.spacing(2), width:'70vw', position:'absolute', bottom:0, left:0}}>
              {RichText.asText(props.position.data.title)}
          </Typography>
      </ModelViewer>
    </div>
    <Paper className={classes.contentLayer} elevation={24}>
      <DialogContent >
      <div style={{textAlign: 'Center', margin: theme.spacing(2)}}>
        {
          props.position.tags.map(tag => (
            <Chip 
              key={tag}
              label={tag} 
              style={{margin: "3px"}}
              variant="outlined" 
              color={(props.currentTags.indexOf(tag)> -1) ? 'primary' : 'default'}
              size="medium" 
              clickable={true} 
              onClick={() => {props.onTagClicked(tag)}}
              />
          ))
        }
      </div>
        <RichText render={props.position.data.body} htmlSerializer={htmlSerializer} linkResolver={linkResolver} />
      </DialogContent>
      <DialogContent dividers>
      <div style={{textAlign: 'Center', margin: theme.spacing(2)}}>
          {(navigator.share)? shareSection : null}
          {playListIcon}
        </div>
      
      </DialogContent>
    </Paper>
   </Aux>;
  }

  return (
      <Dialog  PaperProps={{ref:dialogRef}} fullScreen={fullScreen} fullWidth maxWidth={'md'} onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.open}>
        <div>
        {body}
        </div>
      </Dialog>
  );
}