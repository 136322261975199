import React, {useState,useEffect} from 'react';
import Layout from './components/Layout/Layout';
import ReactGA from 'react-ga';
import ttiPolyfill from 'tti-polyfill';
import {BrowserRouter} from 'react-router-dom';
import Prismic from 'prismic-javascript';
import {Helmet} from "react-helmet";
import firebase from 'firebase/app';
import 'firebase/storage';
import { Route, Redirect, Switch } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import HeroBanner from './components/HeroBanner/HeroBanner';
import PositionsViewer from './containers/PositionsViewer/PositionsViewer';
import Playlist from './components/Playlist/Playlist'

//use local storage for playlist
import { useLocalStorage } from 'react-localstorage-helper';

ReactGA.initialize('UA-145427207-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const theme = createMuiTheme({
  palette: {
    primary: { main: '#d12d36'},
    //primary: { main: '#841b2d'}, 
    secondary: { main: '#f2f3f4' }, 
  },
  typography: {
    h1: {
      fontFamily:'Poiret One'
    },
    h2: {
      fontFamily:'Poiret One'
    },
    h3: {
      fontFamily:'Poiret One'
    },
    h5: {
      fontFamily:'Poiret One'
    },
    h6: {
      fontFamily:'Poiret One'
    }
  }
});

const prod_config = {
  apiKey: 'AIzaSyDAhJNqiBXOH5Aue0CmRJ81y3cQX6Tnvb8',
  databaseURL: 'https://kamality-f0b48.firebaseio.com',
  storageBucket: 'gs://kamality-f0b48.appspot.com'
};

const staging_config = {
  apiKey: 'AIzaSyDyKR7kGo95Fc8QHNgLVGCsh1DNP5RV_bo',
  databaseURL: 'https://kamality-f0b48.firebaseio.com',
  storageBucket: 'gs://kamality-staging.appspot.com'
};

firebase.initializeApp(prod_config);

ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
  ReactGA.timing({
    category: "Load Performace",
    variable: 'Time to Interactive',
    value: tti 
  })
  //console.log(`Time to interactive: ${tti}`);
});

const callback = list => {
  list.getEntries().forEach(entry => {
    //console.log(entry);
      ReactGA.timing({
        category: "Load Performace",
        variable: "Server Latency",
        value: entry.responseStart - entry.requestStart
      });
      ReactGA.timing({
        category: "Load Performace",
        variable: "Download Time",
        value: entry.responseEnd - entry.responseStart
      });
      ReactGA.timing({
        category: "Load Performace",
        variable: "Total App Load Time",
        value: entry.responseEnd - entry.requestStart
      });
      //console.log(`Total App Load Time ${entry.responseEnd - entry.requestStart}`)
})
}

const observer = new PerformanceObserver(callback);
observer.observe({entryTypes: [
                                   'navigation',
                                  // 'paint',
                                  // 'resource',
                                  // 'mark',
                                  // 'measure',
                                  // 'frame',
                                  // 'longtask'
                                ]});

const PrismicCMSRepo = 'https://kamality.cdn.prismic.io/api/v2';

function App() {

  //Positions
  const [positions, setPositions] = useState(null);
  const [orderedTagList, setOrderedTagList] = useState(null);

   //Playlist
   const [playListUIDs, setPlayListUIDs] = useLocalStorage('Playlist', []);
   const [playlistName, setPlaylistName] = useLocalStorage('Playlist Name', "Hello Playlist");

  useEffect(() => {
    const Client = Prismic.client(PrismicCMSRepo);
      const fetchData = async () => {
        const response = await Client.query(Prismic.Predicates.at('document.type', 'card'), {pageSize:100});
        if (response) {
         //console.log(response);
          const positions = response.results;
          positions.sort(comparePositions);
          setPositions(positions);
      }
    }
    const fetchOrderedTags = async () => {
        const response = await Client.query(Prismic.Predicates.at('document.type', 'orderedtaglist'), {pageSize:100});
        if (response) {
         // console.log(response);
          const results = response.results[0].data.orderedtags; 
          const tagList = results.map(x => x.tags);
          setOrderedTagList(tagList);    
      }
    }
    //"https://firebasestorage.googleapis.com/v0/b/kamality-f0b48.appspot.com/o/GLB%2Fpeepshow.glb?alt=media&token=536985b7-e10f-4344-ba18-0ea3780d011d"
    /*const fetchModelURLs = async () => {
      // Get a reference to the storage service, which is used to create references in your storage bucket
      const storage = firebase.storage();
      // Create a storage reference from our storage service
      const storageRef = storage.ref();
      // Create a reference under which you want to list
      const listRef = storageRef.child('GLB/');
      // Find all the prefixes and items.
      listRef.listAll().then(function(res) {
        res.items.forEach(function(itemRef) {
          // All the items under listRef.
          console.log(itemRef.getDownloadURL())
        });
      }).catch(function(error) {
        // Uh-oh, an error occurred!
      });
    }*/

  fetchData();
  fetchOrderedTags();
  //fetchModelURLs();
  }, []);

  const comparePositions = (position1, position2) => {
    if(position1.data.difficulty === position2.data.difficulty) return 0;
    if(position1.data.difficulty === 'Beginner') return -1;
    if(position1.data.difficulty === 'Intermediate' && position2.data.difficulty === 'Advanced') return -1;
    return 1;
  }
  return(
      <BrowserRouter>
        <div className="app" style={{width:'100%', height: '100%'}}>
          <ThemeProvider theme={theme}>
          <CssBaseline />
            <Helmet titleTemplate="%s - Kamality" defaultTitle="Kamality">
                <meta charSet="utf-8" />
                <meta name="description" content="Kamality [Kama · Sutra · Augmented · Reality] is a modern guide to sex positions, intended to be a safe space for all genders and sexual orientations. The website is cleanly designed, contains tasteful interactive 3D visualizations, and features all original content. Accessing Kamality is private, anonymous, and free."/>
                <meta name="keywords" content="kama,sutra,augmented,reality,sexuality,sex,position,positions,wellness,empowerment,positivity, connection,education,healthy,lifestyles,fulfillment,life,gender,orientation,3d"/>
                <meta name="author" content="Kamality"/>
            </Helmet>
            <Switch>
              <Route path={["/positions/:uid", "/positions/"]} render={(routeProps) => (
                <Layout positions={positions}>
                  <HeroBanner/>
                  <PositionsViewer {...routeProps} positions={positions} orderedTagList={orderedTagList} playListUIDs={playListUIDs} setPlayListUIDs={setPlayListUIDs} playlistName={playlistName} setPlaylistName={setPlaylistName}/>
                </Layout>
              )}/>
              <Route path={["/playlist/:uid", "/playlist/"]} render={(routeProps) => <Playlist {...routeProps} positions={positions} playListUIDs={playListUIDs} setPlayListUIDs={setPlayListUIDs} playlistName={playlistName} setPlaylistName={setPlaylistName}/>}/>
              <Redirect exact from="/" to="/positions"/>
            </Switch>
          </ThemeProvider>
        </div>
      </BrowserRouter>
  );

  
}

export default App;