import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

export default function PositionCardLoading() {
    return (
        <Card style={{height:'500px',display: 'flex', flexDirection: 'column'}}>
            <Skeleton variant="rect" width={'100%'} height={200} />
            <CardContent>
                <React.Fragment>
                    <Skeleton height={12} />
                    <Skeleton height={12} />
                    <Skeleton height={12} />
                    <Skeleton height={12} width="80%" />
                </React.Fragment>
          </CardContent>
        </Card>
    )
}
    
