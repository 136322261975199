import React from 'react';
import Aux from '../../hoc/Aux';

import Footer from '../Footer/Footer';
import TopBar from '../../containers/TopBar/TopBar';




const layout = (props) => (
    <Aux>
        <TopBar setSearchKeyword={props.setSearchKeyword} positions={props.positions}/>
        <main>
          {props.children}
        </main>
      <Footer/>
    </Aux>
);

export default layout; 