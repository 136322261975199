import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PositionSorter from '../../components/PositionSorter/PositionSorter'

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles( theme => ({
    root: {
     marginBottom: '12px'
    },
    filter: {
        width: '100%',
        overflowX: 'scroll'
    },
    filterButton: {
        whiteSpace: 'nowrap',
        color: '#000'
    },
    bottomRow: {
        width: '100%',
        margin: '0 auto',
        display: 'flex',
       alignItems: 'center',
        marginTop: '8px'
    },
    counterText: {
    
      marginRight: '6px',
      marginLeft: '6px'
    },
    clearButton: {
        margin: theme.spacing(1),
    }
  }));

const FilterBar = props => {
    const classes = useStyles();

    const handleFilterSelection = (event, newFilter) => {

        if(newFilter[newFilter.length-1] === 'All' ) 
        {
            props.onClearFilterTags();
            return;
        }
        //remove selection
        if (newFilter.length < props.currentTags.length)
        {
            const difference = props.currentTags.filter(x => !newFilter.includes(x));
            if(difference.length > 0)
                props.handleDeleteFilterTag(difference[0]);
              
        } 
        //add selection
        else
        {
            const tag = newFilter[newFilter.length-1];
            props.onCardTagChipClicked(tag);
        }

        
    }

    const handleClearFilter = event => {
        props.onClearFilterTags();
    }

    return (
        <div className={classes.root}>   
            <div className={classes.filter}>
                <ToggleButtonGroup color="primary" aria-label="text primary button group" value={(props.currentTags.length === 0) ? ['All'] : props.currentTags} onChange={handleFilterSelection}>
                    <ToggleButton className={classes.filterButton} value='All'>All</ToggleButton>
                    {
                        props.allTags.map(tag => (
                            <ToggleButton key={tag} className={classes.filterButton} value={tag}>{tag}</ToggleButton>
                        ))
                    }
                </ToggleButtonGroup>
            </div>
            <div className={classes.bottomRow}>
                <PositionSorter sortFilteredPositions={props.sortFilteredPositions} setSortOrder={props.setSortOrder} sortOrder={props.sortOrder} SortingOptions={props.SortingOptions}/>
                <Typography className={classes.counterText} align="center" variant="body2">{props.positionCount} Positions</Typography>
                {
                    (props.currentTags.length === 0)
                        ? null
                        : <Button className={classes.clearButton} size="small" onClick={() => handleClearFilter()} >Clear Filter <ClearIcon color="action" fontSize="small" /></Button>
                }
                
            </div>
        </div>
    );
}

export default FilterBar;