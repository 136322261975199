import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SwapVertSharpIcon from '@material-ui/icons/SwapVertSharp';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        
      },
      selector: {
        margin: theme.spacing(1),
        outlined: "0px",
        textTransform: 'uppercase',
        fontSize: '0.8125rem',
        fontWeight: '500',
        lineHeight: '1.75',
        borderRadius: '4px',
        letterSpacing: '0.04857em',
      }
}));




export default function PositionSorter(props) {

    const classes = useStyles();
    


    const OnSelectionChanged = (event, child) => {
        props.setSortOrder(event.target.value);
        props.sortFilteredPositions(event.target.value);
    }

    return(
        <FormControl className={classes.formControl}>
      
        <Select 
            size="small" 
            className={classes.selector} 
            disableUnderline 
            value={props.sortOrder}
            IconComponent = {SwapVertSharpIcon} 
            input={<Input id="grouped-select" onChange={(event, child) => OnSelectionChanged(event,child)}/>}>
          <ListSubheader>Sort By</ListSubheader>
            <MenuItem value={props.SortingOptions.Beginner}>Beginner</MenuItem>
            <MenuItem value={props.SortingOptions.Intermediate}>Intermediate</MenuItem>
            <MenuItem value={props.SortingOptions.Advanced}>Advanced</MenuItem>
        </Select>
      </FormControl>
    );

}