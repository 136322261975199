import React, {useState,useEffect} from 'react';
import PositionDialog from '../../components/PositionDialog/PositionDialog';
import PositionCard from '../../components/PositionCard/PositionCard';
import PositionCardLoading from '../../components/PositionCard/PositionCardLoading';
import FilterBar from '../../components/FilterBar/FilterBar'
//Router
import {Link} from 'react-router-dom';
//Material
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import Skeleton from '@material-ui/lab/Skeleton';
//analytics
import ReactGA from 'react-ga';
//scroll
import InfiniteScroll from 'react-infinite-scroller';



const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      progress: {
        margin: theme.spacing(24),
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
      },
      categories: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
      },
      chip: {
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
      },
      chipFilterArray:{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(2),
      },
      chipFilter:{
        margin: theme.spacing(0.5),
      },
      fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      gridRoot: {
        minHeight: '200px'
      },
}));

const SortingOptions = {
  Beginner: {attribute:["data","difficulty"], order:["Beginner", "Intermediate", "Advanced"]},
  Intermediate: {attribute:["data","difficulty"], order:["Intermediate", "Advanced", "Beginner"]},
  Advanced: {attribute:["data","difficulty"], order:[ "Advanced",  "Intermediate", "Beginner"]}
};
 
export default function PositionsViewer(props) {
  
    const [showModal, setShowModal] = useState(false);
    const [currentPosition, setCurrentPosition] = useState(null);
    const [allTags, setAllTags] = useState(null);
    const [currentTags, setCurrentTags] = useState([]);
    const [filteredDoc, setFilteredDoc] = useState(null);
    const [sortOrder, setSortOrder] = React.useState(SortingOptions.Advanced);

    //Dynamic load with infinite scroll
    const [scrollRange, setScrollRange] = useState(6);
    const [hasMoreItems, setHasMoreItems] = useState(true);

    useEffect(() => {
      if(!props.positions) return;
      setFilteredDoc(props.positions); 
      
    }, [props.positions]);

    useEffect(() => {
      if(!props.positions || !props.orderedTagList) return;

      const allTags = props.positions.map(position => position.tags).flat();
      const uniqueTags = Array.from(new Set(allTags));
      //console.log(uniqueTags);
      //console.log(props.orderedTagList);

      let tagList = [...props.orderedTagList];
      uniqueTags.forEach((tag) => {
        if(!tagList.includes(tag))
          tagList.push(tag);
      })

      setAllTags(tagList);
    }, [props.positions, props.orderedTagList])

   /* useEffect(() => {
      //If we press/swipe back in the position detail dialog, hide the dialog.
      //console.log(props.history);
      if(props.history.action != null && props.history.action === "POP")
      {
        if(showModal)
          setShowModal(false);
      }

    }, [props.history.action, showModal]);*/

    /*useEffect(() => {
      if(typeof props.location.state === 'undefined') return;
      
      if(props.location.state.hasOwnProperty('playlistName'))
        setPlaylistName(props.location.state.playlistName);
      if(props.location.state.hasOwnProperty('playlistPositions'))
        setPlayListUIDs(props.location.state.playlistPositions);
    }, [props.location.state]);*/

    useEffect(() => {
     
      if(props.match.params.uid && props.positions)
      {
        for(let card of props.positions)
        {
          if(card.uid === props.match.params.uid)
          {
            setCurrentPosition(card);
            setShowModal(true);
            break;
          }
        }
      }
    }, [props.match.params.uid, props.positions]);

    useEffect(() => {
      if(!props.location.search) return;
      const query = new URLSearchParams(props.location.search);
      const searchTag = query.get('tag')
      //console.log(searchTag)//123
      setCurrentTags([searchTag]);
    },[props.location.search])

    useEffect(() => {
      if(!props.positions) return;
      let cards = [];
      //console.log(currentTags);
      for(let position of props.positions)
      {
        const cardTags = position.tags;
        const filterTags = currentTags;
        if(filterTags.every(elem => cardTags.indexOf(elem) > -1)) // returns true for any condition on an empty array, those returns all positions
        {
          cards.push(position);
        }
      }
  
      //console.log(cards);
      sortFilteredPositions(sortOrder, cards);
 
    },[currentTags, props.positions]);

    /*useEffect(() => {
      console.log(playListUIDs);
    }, [playListUIDs]);*/

    const onPositionCardClicked = (card_id) => {
      props.positions.forEach(element => {
            if(element.id === card_id)
                setCurrentPosition(element);
        });

        setShowModal(true);
    };

    const onPositionCardDismissed = () => {
        setShowModal(false);
        props.history.replace("/positions/");
    }

    const onCardTagChipClicked = (tag) => {
      if(currentTags.includes(tag)) return;
      ReactGA.event({
        category: 'Tags',
        action: 'Selected a Tag',
        label: tag
      });
      const newTagState = [...currentTags, tag];
      setCurrentTags(newTagState);
      setScrollRange(6);
      setHasMoreItems(true);
    }

    const onClearFilterTags= () => {
      ReactGA.event({
        category: 'Tags',
        action: 'Cleared Tags',
        label: 'All'
      });
      
      setScrollRange(6);
      setHasMoreItems(true);
      setCurrentTags([]);
    }

    const handleDeleteFilterTag = tagToDelete => {

      const result = currentTags.filter(tag => tag !== tagToDelete);
      setCurrentTags(result);
      setScrollRange(6);
      setHasMoreItems(true);
      ReactGA.event({
        category: 'Tags',
        action: 'Removed a Tag',
        label: tagToDelete
      });
    };

    const onAddToPlaylist = (uid) => {
      props.setPlayListUIDs([...props.playListUIDs, uid]);
    }

    const onRemoveFromPlaylist = (uid) => {
      const itemIndex = props.playListUIDs.indexOf(uid);
      if(itemIndex > -1)
      {
        const playlistCopy = [...props.playListUIDs];
        playlistCopy.splice(itemIndex, 1);
        props.setPlayListUIDs(playlistCopy);
      }
    }

    //const PositionCard = React.lazy(() => import('../../components/PositionCard/PositionCard'));

    const sortFilteredPositions = (sortingOptions, unsortedPositions = filteredDoc) => 
    {

      const filteredPositions = [...unsortedPositions];

      filteredPositions.sort((a,b) => {
        //A is 'begginer' and B is 'Intermediate' OR 'Advanced'
        if(a[sortingOptions.attribute[0]][sortingOptions.attribute[1]] === sortingOptions.order[0] && b[sortingOptions.attribute[0]][sortingOptions.attribute[1]] !== sortingOptions.order[0])
            return -1;
        //A is 'Intermedite' and B is 'Advanced'
        else if(a[sortingOptions.attribute[0]][sortingOptions.attribute[1]] === sortingOptions.order[1] && b[sortingOptions.attribute[0]][sortingOptions.attribute[1]] === sortingOptions.order[2])
            return -1;
        else if(a[sortingOptions.attribute[0]][sortingOptions.attribute[1]] === b[sortingOptions.attribute[0]][sortingOptions.attribute[1]])
            return 0;
        else
            return 1;
      } );

     
      setFilteredDoc(filteredPositions);
    }

    const loadItems = page => {
      const scrollDif = filteredDoc.length - scrollRange;

      //console.log(`Cards: ${filteredDoc.length} ScrollRange: ${scrollRange} ScrollDif: ${scrollDif}`);

      if(scrollDif > 6)
      {
        setScrollRange(scrollRange+6);
        setHasMoreItems(true);
      
      }
      else
      {
        setScrollRange(scrollRange+scrollDif);
        setHasMoreItems(false);
      }
        
    }

    let items = [];
    if(filteredDoc != null)
    {
      filteredDoc.slice(0, scrollRange).map((position,index) => {
        items.push(
          <Grid item key={position.id} xs={12} sm={6} md={4}>
            <PositionCard index={index} position={position} cardClicked={onPositionCardClicked} tagClicked={onCardTagChipClicked} currentTags={currentTags} isOnPlaylist={props.playListUIDs.includes(position.uid)} onAddToPlaylist={onAddToPlaylist} onRemoveFromPlaylist={onRemoveFromPlaylist}/>
          </Grid>
          );
        }
      )
    }
    

    const classes = useStyles();

    return (
      filteredDoc ? (
        <Container  maxWidth="md" className={classes.cardGrid} id='positions-container'>
          {
            (allTags != null)
            ?  <FilterBar allTags={allTags} currentTags={currentTags} onCardTagChipClicked={onCardTagChipClicked} handleDeleteFilterTag={handleDeleteFilterTag} onClearFilterTags={onClearFilterTags} positionCount={filteredDoc.length} setSortOrder={setSortOrder} sortOrder={sortOrder} sortFilteredPositions={sortFilteredPositions} SortingOptions={SortingOptions}></FilterBar>
             : <div>
                <Skeleton variant="rect" width={'100%'} height={48} style={{marginBottom: '30px'}}/>
               
               </div>
          }
          <PositionDialog open={showModal} onClose={onPositionCardDismissed} position={currentPosition} currentTags={currentTags} onTagClicked={onCardTagChipClicked} isOnPlaylist={currentPosition ? props.playListUIDs.includes(currentPosition.uid) : null} onAddToPlaylist={onAddToPlaylist} onRemoveFromPlaylist={onRemoveFromPlaylist}/>
          
            {/*filteredDoc.map((position,index) => (
            <Grid item key={position.id} xs={12} sm={6} md={4}>
              <PositionCard index={index} position={position} cardClicked={onPositionCardClicked} tagClicked={onCardTagChipClicked} currentTags={currentTags} isOnPlaylist={playListUIDs.includes(position.uid)} onAddToPlaylist={onAddToPlaylist} onRemoveFromPlaylist={onRemoveFromPlaylist}/>
            </Grid>))*/}
              <InfiniteScroll
                pageStart={0}
                loadMore={loadItems}
                hasMore={(filteredDoc.length - scrollRange) !== 0}
                loader={ <Grid item xs={12} sm={6} md={4} style={{paddingTop:'24px'}} key={0}>
                          <PositionCardLoading />
                          </Grid>}
              >
                <Grid container justify="flex-start" alignItems="flex-start" spacing={4} className={classes.gridRoot}>
                  {items} 
                </Grid>
            </InfiniteScroll>
        
          {(props.playListUIDs.length > 0) 
          ?
          <Link to={{ pathname: `/playlist`}}  >
            <Zoom in={true}> 
              <Fab aria-label="play playlist fab" className={classes.fab} color="primary">
                <PlaylistPlayIcon/>
              </Fab>
            </Zoom>
          </Link>
          : 
          <Zoom in={false}> 
            <Fab aria-label="play playlist fab" className={classes.fab} color="primary">
              <PlaylistPlayIcon/>
            </Fab>
          </Zoom>}
        </Container>
    ): 
    <Container maxWidth="md" className={classes.cardGrid} style={{height:'1150px'}}>
      <div>
                <Skeleton variant="rect" width={'100%'} height={48} style={{marginBottom: '30px'}}/>
               
      </div>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <PositionCardLoading/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <PositionCardLoading/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <PositionCardLoading/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <PositionCardLoading/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <PositionCardLoading/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <PositionCardLoading/>
        </Grid>
      </Grid>
    </Container>);
}