import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    heroContent: {
        padding: theme.spacing(8, 0, 6),
        display: 'flex',
      },
    title: {
      [theme.breakpoints.down('sm')]: {
        fontSize:'60px',
        letterSpacing: '8px',
      },
      [theme.breakpoints.up('md')]: {
        letterSpacing: '10px',
      },  
      
    },
    subTitle: {
     fontSize: '24px',
     lineHeight: '135%'
    }
  }));

export default function HeroBanner() {
    const classes = useStyles();

    return (    
    <Paper className={classes.heroContent} >
          <Container maxWidth="md" >
            <Typography className={classes.title} variant="h1" align="center" color="textPrimary" gutterBottom>
              KAMALITY
            </Typography>
            <Typography className={classes.subTitle} variant="subtitle1" align="center" color="textSecondary" >
              A modern take on the ancient teachings of sexual wellness and empowerment.
            </Typography>
          </Container>
    </Paper>
    );
}
//Sexuality, connection, and fulfillment in life.
/*const herobanner = () => {

    //const theme = useTheme();

    const heroStyle = {

    };

    const banner = 
    <div >
          <Container maxWidth="sm" style = {heroStyle}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Kamality
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              This is the hero banner for the website. Kamality is....
            </Typography>
          </Container>
    </div>;
        return banner;
};

export default herobanner;*/