import React from 'react';
import { useTheme } from '@material-ui/styles';
import '@google/model-viewer';
import '@magicleap/prismatic';
import backgroundHDR from '../../assets/the_sky_is_on_fire_1k.hdr'
import lightRoomHDR from '../../assets/lightroom_14b.hdr'

const modelViewerRef = React.createRef();

const ModelViewer = (props) => {
    const theme = useTheme();
    return (
        <model-viewer 
            ref = {modelViewerRef}
            environment-image={backgroundHDR}
            style={{width:props.width, height:props.height, posterColor: theme.palette.primary.main, backgroundColor: theme.palette.secondary.main}}  
            src={props.src} 
            ios-src={props.usdz} 
            alt={props.alt} 
            shadow-intensity="2" 
            shadow-softness="0.1"
            camera-controls  
            ar
            exposure="1.4"
            auto-rotate
            interaction-prompt={(props.interactionPrompt)? props.interactionPrompt : 'none'}
            interaction-prompt-style="wiggle"
            interaction-prompt-threshold="5000"
            camera-orbit={(props.zoom)? ("0deg 75deg " + props.zoom) : "0deg 75deg 105%"}
           >
                {props.children}
        </model-viewer>
    );
};

export default ModelViewer;