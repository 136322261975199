import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Rating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import firebase from 'firebase/app';
import 'firebase/database';
import Axios from 'axios';
import {isMobile} from 'react-device-detect';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ReactGA from 'react-ga';

const StyledRating = withStyles({
    iconFilled: {
      color: '#ff6d75',
    },
    iconHover: {
      color: '#ff3d47',
    },
  })(Rating);
  
function getLabelText(value) {
    return `${value} Heart${value !== 1 ? 's' : ''}`;
}

export default function FeedbackDialog(props)
{
    const [rating, setRating] = useState(0.0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (event) => {
        
        event.preventDefault();

        const d = new Date();
        const data = { name, email, message, rating, time: d.getTime() };

        Axios.post('https://us-central1-kamality-f0b48.cloudfunctions.net/submit', data)
        .then(res => {
            console.log('feedback submit success');
            if (firebase) {
                return firebase
                    .database()
                    .ref('contacts')
                    .push(data);
            }
        })
        .catch(error => {
            console.log(error);
        });

        ReactGA.event({
            category: 'Feedback',
            action: 'Feedback Submitted',
            label: message,
            value:rating
        });

        resetAndClose();
    }

    const resetAndClose = () => {
        setRating(0.0);
        setName('');
        setEmail('');
        setMessage('');
        props.onContactClosed();
    }

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    }

    const handleRatingChange = (event, value) => {
        setRating(value);
    }

    return (
        
        <Dialog fullScreen={isMobile}  open={props.showContact} onClose={resetAndClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Feedback</DialogTitle>
            <ValidatorForm 
                onSubmit={handleSubmit}
                onError={errors => console.log(errors)}
            >
             <DialogContent>
             <DialogContentText>
                Drop us a line and help make Kamality better! How was your experience?
             </DialogContentText>
                <StyledRating
                    name="customized-color"
                    value={rating}
                    onChange={handleRatingChange}
                    getLabelText={getLabelText}
                    precision={0.5}
                    icon={<FavoriteIcon fontSize="inherit" />}
                    />
                <TextValidator
                    value={name}
                    onChange={handleNameChange}
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    validators={['required']}
                    errorMessages={['this field is required']}
                />
                <TextValidator
                    value={email}
                    onChange={handleEmailChange}
                    margin="dense"
                    id="name"
                    label="Email (optional, needed for us to reply)"
                    type="email"
                    fullWidth
                    validators={['isEmail']}
                    errorMessages={['email is not valid']}
                />
               <TextValidator
                    value={message}
                    onChange={handleMessageChange}
                    id="standard-multiline-flexible"
                    label="Message"
                    multiline
                    rowsMax="4"
                    margin="normal"
                    fullWidth
                    validators={['required']}
                    errorMessages={['this field is required']}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={resetAndClose} >
                    Cancel
                </Button>
                <Button type="submit" color="primary">
                    Submit
                </Button>
            </DialogActions>
            </ValidatorForm>
        </Dialog>
        
    );
}