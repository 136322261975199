import React , {useEffect} from 'react';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from'@material-ui/core/MenuItem';
import { fade, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Autosuggest from 'react-autosuggest';
import { RichText } from 'prismic-reactjs';
//import {Link} from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
//import {isMobile} from 'react-device-detect';
import Button from '@material-ui/core/Button';
import FeedbackDialog from '../../../components/FeedbackDialog/FeedbackDialog'

//analytics
import ReactGA from 'react-ga';

const suggestions = [];

const renderInputComponent = (inputProps) => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;
  
    return (
      <TextField
        fullWidth
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
            
          },
          disableUnderline: true
        }}
        {...other}
      />
    );
}

  const renderSectionTitle = (section) => {

        if(section.title === 'empty') return null;

        return (
        <Typography variant="subtitle1" display="block">
            {section.title.toUpperCase()}
        </Typography>
      );
  }

  const getSectionSuggestions = (section) => {
    return section.suggestions;
  }

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const filteredData = suggestions.map(section => {
        return{
            title: section.title,
            suggestions: section.suggestions.filter(suggestion => 
                {
                    const suggestionWords = suggestion.text.toLowerCase().split(' ');
                
                    for (let word of suggestionWords)
                        if(word.slice(0,inputLength) === inputValue) return true;
                    return false;
                })
        };
    }).filter(suggestion => suggestion.suggestions.length > 0);
    const noFilteredData = [{title:'empty', suggestions:[{id:'empty',text:value.trim()}]}];
    return inputLength === 0 ? [] : (filteredData.length === 0 ? noFilteredData : filteredData);
    
  }

  const getSuggestionValue = suggestion => suggestion.title;
  
  const useStyles = makeStyles(theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 120,
          '&:focus': {
            width: 200,
          },
        },
      },
      searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      suggestionHeader:{
        padding: theme.spacing(2)
      },
      suggestion:{
        textDecoration: 'none',
        color: 'textPrimary'
      },
      suggestionList:{
        margin: 0,
        marginLeft: theme.spacing(1),
        padding: 0,
        listStyleType: 'none',
      }
  }));

export default function SearchBar(props)
{
   

    useEffect(() => {
        if(!props.positions) return;
       //populate our search bar suggestions...
       //console.log('search bar');
       //console.log(props.positions);
       suggestions.splice(0, suggestions.length);

       const positionSuggestions =  {title: 'Positions', suggestions:[]};
       const tagArray = [];
        const cardSuggestions = {title: 'Categories', suggestions:[]};
       for(let position of props.positions)
        {
            positionSuggestions.suggestions.push({'uid':position.uid, 'text':RichText.asText(position.data.title)});
            
            const cardTags = position.tags;
            for(let tag of cardTags)
            {
                if(tagArray.indexOf(tag)===-1) tagArray.push(tag);
            }
        }

        for(let tag of tagArray)
        {
            cardSuggestions.suggestions.push({'tag':tag,'text':tag});
        }

        suggestions.push(positionSuggestions);
        suggestions.push(cardSuggestions);

      }, [props.positions]);

    const classes = useStyles();

    const [state, setState] = React.useState({
        popper: '',
      });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [stateSuggestions, setSuggestions] = React.useState([]);
    const [showContact, setShowContact] = React.useState(false);

    const handleSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
      };
    
      const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
      };
    
      const handleChange = name => (event, { newValue }) => {
        setState({
          ...state,
          [name]: typeof newValue !== 'undefined' ? newValue : '',
        });
      };

      const onContactClicked = () => 
      {
        setShowContact(true);
      }

      const onContactClosed = () => 
      {
        setShowContact(false);
      }

      const renderSuggestion = (suggestion) => {

        if(suggestion.id && suggestion.id === 'empty')
        {
          ReactGA.event({
            category: 'Search',
            action: 'No Results',
            label: suggestion.text
          });

            return (
              <div  style={{padding: 14}}>
                <Typography variant="body1">
                    No results for "{suggestion.text}"
                </Typography>
                <Button color="primary" onClick={onContactClicked}>
                    What did you hope to find?
                </Button>
                </div>
            );
        }
    
        let newPath, newTag, newHash;
    
        if("uid" in suggestion)
        {
            newPath="/positions/"+suggestion.uid;
            newTag = null;
            newHash = null;
        }
        else
        {
            newPath="/positions/";
            newTag="?tag="+suggestion.text;
            newHash = 'positions-container';
    
        }
    
        return (
                <Link to={{pathname: newPath, search: newTag, hash: newHash}} smooth style={{ textDecoration: 'none', color: '#000' }}>
                <MenuItem  component='span'> 
                    <Typography variant="body1">
                        {suggestion.text}
                    </Typography>
                </MenuItem>
                </Link>
        );
    }

    const autosuggestProps = {
        renderInputComponent,
        suggestions: stateSuggestions,
        onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: handleSuggestionsClearRequested,
        getSuggestionValue,
        renderSuggestion,
      };

      const userMadeSelection = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) =>{
        //console.log(suggestion);
        ReactGA.event({
            category: 'Search',
            action: 'Search Selection',
            label: suggestion.text
          });
      };

    return (
            <div className={classes.search}>
              <FeedbackDialog showContact={showContact} onContactClosed={onContactClosed}/>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <Autosuggest
                    multiSection={true}
                    renderSectionTitle={renderSectionTitle}
                    getSectionSuggestions={getSectionSuggestions}
                    onSuggestionSelected={userMadeSelection}
                    focusInputOnSuggestionClick={false}
                    {...autosuggestProps}
                    inputProps={{
                    classes,
                    id: 'react-autosuggest-popper',
                    label: null,
                    placeholder: 'Search...',
                    value: state.popper,
                    onChange: handleChange('popper'),
                    inputRef: node => {
                        setAnchorEl(node);
                    },
                    InputLabelProps: {
                        shrink: true,
                    },
                    }}
                    theme={{
                        suggestionsList: classes.suggestionList,
                        sectionTitle: classes.suggestionHeader,
                        suggestion: classes.suggestion, 
                    }}
                    renderSuggestionsContainer={options => (
                    <Popper anchorEl={anchorEl} open={Boolean(options.children)}>
                        <Paper
                        square
                        {...options.containerProps}
                        style={{ width: anchorEl ? anchorEl.clientWidth : undefined }}
                        >
                        {options.children}
                        </Paper>
                    </Popper>
                    )}
                />
           
          </div>
    );
}
