import React, {useState,useEffect} from 'react';
import ModelViewer from '../ModelViewer/ModelViewer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PlaylistMenu from './PlaylistMenu'
import {Link} from 'react-router-dom';
import arrayMove from "array-move";
import {Helmet} from "react-helmet";
import ReactGA from 'react-ga';
import { RichText } from 'prismic-reactjs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import UseWindowDimensions from '../../hooks/useWindowDimensions';
import firebase from 'firebase/app';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.secondary.main
      },
    leftDiv: {
        [theme.breakpoints.down('sm')]: {
            width: '0%',
        },
        [theme.breakpoints.up('md')]: {
            width: '35%',
        },    
    },
    modelViewer: {
        MozUserSelect:'none',
        WebkitUserSelect:'none',
        MSUserSelect:'none',
        userSelect: 'none',
    },
    progressBar: {
        position:'fixed',
        bottom:'0px',
        width:'100%',
        height:'10px'
    },
    expandButton: {
        position: 'fixed',
        bottom: '10%',
        left: '0',
        right: '0', 
        marginLeft: 'auto',
        marginRight: 'auto', 
        width: '200px'
    },
    controlButtons:{
        position: 'fixed',
        bottom: '2%',
        left: '0',
        right: '0', 
        marginLeft: 'auto',
        marginRight: 'auto', 
        width: '200px'
    },
    button:{
        margin: theme.spacing(1),
    },
    closeButton:{
        margin: theme.spacing(1),
        position: 'fixed',
        top: '0',
        right: '0',
    }
}));

export default function Playlist(props) {

    const [playlistPositions, setPlaylistPositions] = useState(null);
    const [currentPlayIndex, setCurrentPlayIndex] = useState(0);
    const [isPaused, setPaused] = useState(false);
    const [progress, setProgress] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [GLB, setGLB] = useState(null);

    const classes = useStyles();
    const theme = useTheme();

    const { width, height } = UseWindowDimensions();

    useEffect(() => {
        if(props.playListUIDs === null || props.positions === null) return;

        let playList = [];
        for(const position of props.playListUIDs)
        {
            const match = props.positions.find(p => p.uid === position);
            if(match !== undefined)
                playList.push(match);
        }
        setPlaylistPositions(playList);

    },[props.playListUIDs, props.positions]);

    //Run the play list transitions
    useEffect(() => {
        if(playlistPositions === null || playlistPositions.length <= 0 || isPaused) return;

        const normalise = value => value * 100 / totalDuration;
        const denormalise = value => value * totalDuration / 100;
        
        const totalDuration = 60000; //60s
        const timeInterval = 100; //ms so 0.1
        const start = Date.now();
        const previousDelta = denormalise(progress);


        const timerProgress = () => {
            const delta = Date.now() - start + previousDelta; // milliseconds elapsed since start
            setProgress(normalise(delta));
            if(delta >= totalDuration)
            {
                if(currentPlayIndex < playlistPositions.length - 1)
                {
                    setProgress(0);
                    setCurrentPlayIndex(currentPlayIndex+1);
                }
                else
                {
                    clearInterval(timer);
                }
            }
        }

        const timer = setInterval(timerProgress, timeInterval);

        return () => {
            clearInterval(timer);
        };
    }, [currentPlayIndex, playlistPositions, isPaused])

    useEffect(() => {
        if (playlistPositions != null && playlistPositions.length >= currentPlayIndex && playlistPositions.length!==0)
        {
            const storage = firebase.storage();
            storage.ref(`GLB/${playlistPositions[currentPlayIndex].uid}.glb`).getDownloadURL().then( url => {
                //console.log(url);
                setGLB(url);
            })
            .catch(error => {
                console.log(error);
            })
            //setCurrentGLB
            } 
    }, [currentPlayIndex, playlistPositions]);

    const onPlayPause = () => {
        setPaused(!isPaused);
        ReactGA.event({
            category: 'Playlist',
            action: 'On Play / On Pause',
          });
     }

    const onSkipNext = () => {
        if(currentPlayIndex < playlistPositions.length - 1)
        {
            setCurrentPlayIndex(currentPlayIndex+1);
            setProgress(0);
        }    
        ReactGA.event({
            category: 'Playlist',
            action: 'Skip Next',
          });
    }

    const onSkipPrevious = () => {
        if(currentPlayIndex > 0)
        {
            setCurrentPlayIndex(currentPlayIndex-1);
            setProgress(0);
        }

        ReactGA.event({
            category: 'Playlist',
            action: 'Skip Previous',
          });
     }

     const onSetCurrentIndexClick = (index) => {
         //console.log(`onSetCurrentIndexClick ${index}`);
        if(index === currentPlayIndex) return;
        setCurrentPlayIndex(index);
        setProgress(0);

        ReactGA.event({
            category: 'Playlist',
            action: 'Select List Item',
          });
     }

     const onRemoveItemFromPlaylist = (index) => {
        
        ReactGA.event({
            category: 'Playlist',
            action: 'Remove From Playlist',
          });

         //delete this item
        const playListCopy = [...playlistPositions];
        playListCopy.splice(index, 1);
        //console.log(playListCopy);
        //setPlaylistPositions(playListCopy);
        props.setPlayListUIDs(playListCopy.map(p => p.uid));
         //set new playlist index if invalid
        if(playListCopy.length === 0)
        {
            //Empty List
        }
        else if(index > playListCopy.length - 1)
        {
            //deleted last item in list, adjust play index
            setCurrentPlayIndex(playListCopy.length - 1);
        }
        
     }

     const onSortEnd = ({ oldIndex, newIndex }) => {
         const sortedArray = arrayMove(playlistPositions, oldIndex, newIndex);
        setPlaylistPositions(sortedArray);
        setCurrentPlayIndex(newIndex);
        props.setPlayListUIDs(sortedArray.map(p => p.uid));

        
        //props.setPlayListUIDs(getPlaylistPositionsAsUIDList());
        ReactGA.event({
            category: 'Playlist',
            action: 'Sorted Playlist',
          });
      };

      const onUpdatePlaylistName = (newPlaylistName) => {
        props.setPlaylistName(newPlaylistName);
        ReactGA.event({
            category: 'Playlist',
            action: 'Set playlist name',
          });
      }

     const getPlaylistPositionsAsUIDList = () => {
         let uidList = [];

         if(playlistPositions === null) return uidList; 

         for(const position of playlistPositions)
         {
            uidList.push(position.uid)
         }
         return uidList;
     }

     const onExpandDrawer = open => {
        setDrawerOpen(open);
     }

    
    const linkOutState = { playlistName: props.playlistName, playlistPositions:getPlaylistPositionsAsUIDList()};

    return (
        <div className={classes.root} height={height}>
             <Helmet>
                <meta charSet="utf-8" />
                <title>{props.playlistName}</title>
                <meta name="description" content="My custom playlist of sex positions."/>
                <meta name="author" content="Kamality"/>
            </Helmet>
            <div className={classes.leftDiv}>
            </div> 
            <ModelViewer className={classes.modelViewer} width="100%" height={height} src={(playlistPositions != null && playlistPositions.length > 0) ? GLB : null} ar={false}/>
            {
                (useMediaQuery(theme.breakpoints.up('md'))) 
                    ? null
                    : <Button className={classes.expandButton} color="primary" onClick={() => onExpandDrawer(true)}>{(playlistPositions && playlistPositions.length > 0) ? RichText.asText(playlistPositions[currentPlayIndex].data.title) : "Empty Playlist"}  <ExpandLessIcon style={{marginLeft: "6px"}}/> </Button>
            }    
           <div className={classes.controlButtons}>
                <IconButton color="primary" className={classes.button} aria-label="skip-previous" onClick={() => onSkipPrevious()} disabled={(currentPlayIndex===0)?true:false}>
                    <SkipPreviousIcon />
                </IconButton>
                <IconButton color="primary" className={classes.button} aria-label="pause" onClick={() => onPlayPause()} disabled={(playlistPositions===null || playlistPositions.length <= 0)?true : false}>
                {
                    (isPaused) ? <PlayArrowIcon/> : <PauseIcon />
                }
                </IconButton>
                <IconButton color="primary" className={classes.button} aria-label="skip-next" onClick={() => onSkipNext()} disabled={(playlistPositions===null || playlistPositions.length <= 0 || currentPlayIndex===playlistPositions.length - 1)?true:false}>
                    <SkipNextIcon />
                </IconButton>
            </div>
            <Link to={{ pathname: `/positions/`}}  >
                <IconButton  className={classes.closeButton} aria-label="close" >
                        <CloseIcon />
                </IconButton>
            </Link>
            <LinearProgress className={classes.progressBar} variant="determinate" value={progress} color="primary"/>
           <PlaylistMenu playlistName={props.playlistName} setPlaylistName={onUpdatePlaylistName} onSortEnd={onSortEnd} playlistPositions={playlistPositions} currentPlayIndex={currentPlayIndex} setCurrentPlayIndex={onSetCurrentIndexClick} linkOutState={linkOutState} onRemoveItem={onRemoveItemFromPlaylist} drawerOpen={drawerOpen} onExpandDrawer={onExpandDrawer}/>
        </div>
    )


}